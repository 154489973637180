import React, { useState, useEffect } from "react";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/ethereum-provider";
import { Contract, providers, utils } from "ethers";

import Comp from "./Mycomponent";

// @ts-ignore
import logo from "./ppppppp.gif";
import "./App.css";
import { DAI, MRPP } from "./constants";

function App() {
  const web3Modal = new Web3Modal({
    network: "mainnet",
    cacheProvider: true,
    providerOptions: {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          //infuraId: "https://polygon-mainnet.g.alchemy.com/v2/tvnqCxJTJneDxF94jqwEVk7djCDVw8R_", //process.env.REACT_APP_INFURA_ID,
        },
      },
    },
  });

  const [chainId, setChainId] = React.useState<number>(1);
  const [address, setAddress] = React.useState<string>("");
  const [provider, setProvider] = React.useState<providers.Web3Provider>();


  function reset() {
    console.log("reset");
    setAddress("");
    setProvider(undefined);
    web3Modal.clearCachedProvider();
  }

  async function connect() {
  //  if (!process.env.REACT_APP_INFURA_ID) {
  //    throw new Error("Missing Infura Id");
  //  }
    const web3Provider = await web3Modal.connect();

    web3Provider.on("disconnect", reset);

    const accounts = (await web3Provider.enable()) as string[];
    setAddress(accounts[0]);
    setChainId(web3Provider.chainId);

    const provider = new providers.Web3Provider(web3Provider);
    await setProvider(provider);
  }

  return (
    <div className="App">
      <header className="App-header">
        {address ? (<img src={logo} className="App-logo2" alt="logo" />) : (<img src={logo} className="App-logo" alt="logo" />)}
        
        <div>{provider ? "Connected!" : "Not connected"}</div>
        {address ? (
          <>
            <div>{address}</div>
          </>
        ) : (
          <div><button onClick={connect}>Connect ARBITRUM</button><br /> </div>
        )}
        {!address ? <p> WARNING: must be 18+ to enter. no audit, no roadmap, no promises, free mint (for now), and big big dix (hopefully)</p> : <div></div>}
        <div>
          <p><a href="https://discord.gg/zvacPCwj">https://discord.gg/zvacPCwj</a></p>
          {provider ? (
            <Comp p={provider} addr={address} />
          ) : (
            <div></div>
          )}
        </div>
      </header>
      <div>
          {/* body goes here but no css pussy */}
      </div>
    </div>
  );
}

export default App;
