import React, { useState, useEffect } from "react";
import { BigNumberish, Contract, ethers, providers, utils } from "ethers";
import { DAI, MRPP } from "./constants";

import styled from 'styled-components'

interface myprops {
    p: providers.Web3Provider;
    addr: string;
} 

const Comp = ({p, addr}: myprops) => {
    const provider = p
    const contract = new Contract(MRPP.address, MRPP.abi, provider.getSigner());
    const address = addr

    const [, setRerender] = useState(0);

    function makesvg(svg: string) {
        const blob = new Blob([svg], {type: 'image/svg+xml'});
        const url = URL.createObjectURL(blob);
        return url
    }

    const [myids, setmyids] = React.useState<string[]>(["x"]);
    async function getUsersPPs() {
        const ids = await contract.doesthisguyownone(address)
        await setmyids(ids[1])
        const svgArray: JSX.Element[] = []
        for (let i = 0; i < ids[1].length; i++) {
            const raw = await contract.getrawsvg(ids[1][i])
            const svg = makesvg(raw)
            svgArray.push(<div><img id="margins" src={svg}></img><br />{ids[1][i].toString()}<br /><br /></div>)
        }
        return svgArray
    }

    const [tx, setTX] = useState<string>()
    const handleSubmit = () => {
        alert(`Submitting Name ${tx}`)
        return false
    }

    const [mintprice, setmintprice] = useState<string>("0")
    const [mintamnt, setmintamnt] = useState<string>("0")
    const handleMint = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const price = utils.parseUnits(mintprice, 0)
        const amount = utils.parseUnits(mintamnt, 0)
        const cost: ethers.BigNumber = price.mul(amount)
        //console.log(cost.toString())
        const tx = await contract.mint(mintamnt.toString(), {value: cost.toString()})
    }

    const [fightID, setFightId] = useState<string>()
    const pulloutyourdicks = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()  
        await contract.swordfight(fightID)
    }

    const [svg, setSVG] = useState<JSX.Element[]>()
    const [maxsupply, setMaxsupply] = useState<string>()
    const [totalsupply, setTotalsupply] = useState<string>()
    const [godnumber, setgodnumber] = useState<string>()
    const [secondstillfight, setsecondstillfight] = useState<string>()
    const now = Math.floor(Date.now() / 1000)
    
    const loadimages = async () => {
        if (!provider) {
          throw new Error("Provider not connected");
        }
        const userspps = await getUsersPPs()
        await setSVG(userspps)

        const max = await contract.MAX_SUPPLY()
        await setMaxsupply(max)

        const ts = await contract.TOTAL_SUPPLY()
        await setTotalsupply(ts)
        
        const p = await contract.MINTPRICE()
        await setmintprice(p.toString())

        const god = await contract.godnumber()
        await setgodnumber(god)

        const f = await contract.onceanhour()
        const ft = f - now
        await setsecondstillfight(ft.toString())
    };

    const [donateAmount, setDonateAmount] = useState<string>("0")
    const [donateId, setDonateId] = useState<string>("0")
    const helpthenurses = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        await contract.helpthenurses(donateId, {value: ethers.utils.parseUnits(donateAmount, 18)})
    }

    const [rng, setRng] = useState<string>()
    const [rngid, setRngId] = useState<string>()
    const changerng = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        await contract.changegodnumber(rng, rngid)
    }

    useEffect(() => {
        loadimages() 
        
    }, [])

    return(
        <div>
            <MintStyle>
                <div className="parent">
                    <div id="rcorners2" className="child">
                        <h3>MINT</h3>                        
                        {mintprice ?
                            <div>{totalsupply && maxsupply ? <div><p>{totalsupply.toString()} / {maxsupply.toString()}</p><p>Price: {ethers.utils.formatEther(mintprice).toString()} ETH</p></div> : <p>Loading...</p>}
                            <form onSubmit={ e => {handleMint(e)} }> 
                                <div># to mint</div>
                                <label> <input type="text" value={mintamnt} onChange={e => setmintamnt(e.target.value)}  className="mintwidth"/>   </label>
                                <input type="submit" value="Mint" />
                            </form></div> : <div>Loading...</div>
                        }
                        
                    </div>

                    <div id="rcorners2" className="child">
                        <h3>DONATE</h3><br />
                        <form onSubmit={helpthenurses} id="donateform"> 
                            <label> <input type="text" className="mintwidth" value={donateAmount} onChange={e => setDonateAmount(e.target.value)} />   </label><br />
                            id: { myids ? 
                            <select form="donateform" onChange={ e => {setDonateId(e.target.value)}}>
                                <option value="none" selected disabled hidden>Select ID</option>
                                {myids.map((x) => { return <option key={x.toString()}>{x.toString()}</option>})}
                            </select> :
                            <p>Loading...</p>
                            }<br />
                            <input type="submit"  />
                        </form>
                    </div>
                    
                    <div id="rcorners2" className="child">
                        <h3>CHANGE RNG SEED</h3> <br />
                        Current RNG Seed: <br />{godnumber ? godnumber.toString() : <p>????????</p>}
                        <form onSubmit={changerng} id="changerngform">        
                            <label> <input type="text" value={rng} onChange={e => setRng(e.target.value)} />   </label> <br />
                            id: { svg ? 
                            <select form="changerngform" onChange={e => setRngId(e.target.value)} >
                                <option value="none" selected disabled hidden>Select ID</option>
                                {myids.map((x) => { return <option key={x.toString()}>{x.toString()}</option>})}
                            </select> :
                            <p>Loading...</p>
                            }<br />
                            <input type="submit" value="Change" />
                        </form>
                    </div>
                    <div id="rcorners2" className="child">
                        <h3>SWORDFIGHT</h3> <br />
                        {secondstillfight ? <p>Wait {secondstillfight.toString()}s</p> : <p>????????</p>}
                        <form onSubmit={pulloutyourdicks}>
                        id: { svg ? 
                            <select onChange={e => setFightId(e.target.value)}>
                                <option value="none" selected disabled hidden>Select ID</option>
                                {myids.map((x) => { return <option key={x.toString()}>{x.toString()}</option>})}
                            </select> :
                            <p>Loading...</p>
                        }<br />
                        <button type="submit"> 
                            weewee
                        </button>
                        </form> 
                    </div>
                </div>
            </MintStyle>
            <Blurb>
                    <p>protip: this site is bad and u will hafta refresh a lot (nepotism points for anyone who makes a better site)</p>
                    <div id ="rcorners2" >
                        <div className="ass">
                            <p id="fart">mr pp is a dynamic battle nft on Arbitrum. </p>
                            <p>⚫ when mr pp's swordfight, a totally legit rng calculation happens that results in a kill or death. having kills makes your pp longer.</p>
                            <p>⚫ the mega legit rng is based on the rng seed. changing it changes your mr pp.</p>
                            <p>⚫ calling swordfight will change your mr pp over time</p>
                            <p>⚫ donating is a really cool thing to do to support indy devs</p>
                            <p>⚫ no roadmap no nothing this is a meme project for ppl who love pps.</p>
                        </div>
                    </div>
            </Blurb>
            <h1>My Mr PPs</h1>
            {svg ? (
                <Aaaa>
                    <div className="shit">{svg.map(x => x)}</div>
                </Aaaa>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    )
}

export default Comp;

const Blurb = styled.div`
text-align: center;

#rcorners2 {
    border-radius: 25px;
    border: 2px solid #73AD21;
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  #fart {
      text-align: center;
  }

  .ass {
      text-align: left;
      width: fit-content
  }

`

const Aaaa = styled.div`
  margin: 5px;
  #margins {
    margin: 5px;
  }
  text-align: center;

  .shit {
    text-align: center;
    display: flex;  
    flex-wrap: wrap;
    /*margin: 5%;*/
  }
`;

const MintStyle = styled.div`

#rcorners2 {
  border-radius: 25px;
  border: 2px solid #73AD21;
  padding: 20px;
}


.parent {
    /*border: 1px solid black;*/
    text-align: center;
  }
.child {
    display: inline-block;
    margin: 5px;
    h3 {
        margin-top: 0;
    }
}
  
.mintwidth {
      width: 100px;
}

`




/*




*/